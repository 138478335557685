.name {
    font-size: 7rem;
    font-family: Raleway;
    color: white;
    text-shadow: 1px 1px 2px rgb(124, 124, 124);
}

.desc {
    font-size: 2rem;
    font-weight: 600;   
    margin-left: 7px;
    font-family: Nunito Sans;
    color: white;
    text-shadow: 1px 1px 2px rgb(124, 124, 124);
}

.logo {
    height: 3rem;
    width: 3rem;
    filter:invert(1);
    margin: 1rem;
    cursor: pointer;
}