.about-header {
    font-size: 5em;
    font-family: Raleway;
    color: white;
}

.about-content {
    font-size: 1em;
    font-weight: 500;

    display: inline-block;
    width: 74%;
    height: 50%;
    padding: 0 5rem 0 0;
    font-family: "Nunito Sans";
    color: white;
}

.about-photo {
    display: inline-block;
    width: 20rem;
    height: 20rem;
    margin-left: 1rem;
    margin-top: 0;
    position: absolute;
}

.profile-photo {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
