@import './Components/Palette.css';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap');

.App {
  text-align: center;
  background-image: linear-gradient(to top right, var(--paletteColor5Dark), var(--paletteColor3Dark));
  min-height: 100vh;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-image: linear-gradient(to top right, var(--paletteColor5), var(--paletteColor3));
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

.navLink {  
  display: inline-flex;
  margin-right: 10px;
  font-size: calc(10px + 2vmin);
  float: right;
  /* position: absolute; */
  /* margin-top: 0vh; */
  /* margin-left: 50vw; */
  /* justify-self: right; */
  /* align-self: flex-end; */

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
